<template>
  <v-container>
    <v-dialog v-model="welcomeDialog" max-width="600px">
      <Welcome @close="welcomeDialog = false" />
    </v-dialog>

    <v-card color="primary" dark>
      <v-card-title>Nie wiesz, od czego zacząć?</v-card-title>
      <v-card-text>Koniecznie sprawdź nasz przewodnik!</v-card-text>
      <v-card-actions class="pt-0">
        <v-btn text @click="welcomeDialog = true">
          Zobacz
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>O programie</v-card-title>
      <v-card-subtitle>v1.0.0</v-card-subtitle>
      <v-card-text>
        Stworzone z ❤ przez Zespół Pogotowia Ósmoklaisty
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>Masz jakąś sugestię? Chcesz zgłosić błąd?</v-card-title>
      <v-card-subtitle>Jesteśmy ciekawi Twojej opinii</v-card-subtitle>
      <v-card-text>
        Śmiało pisz do nas na kontakt@pogotowie8klasisty.pl. W temacie wpisz
        <i>Portal Ósmoklaisty - sugestia</i> albo
        <i>Portal Ósmoklaisty - zgłoszenie błędu</i>.
      </v-card-text>
      <v-card-actions>
        <v-btn text href="mailto:kontakt@pogotowie8klasisty.pl"
          >Wyślij maila</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card class="mt-4">
      <v-card-text
        ><v-img src="../assets/firebase.png" max-width="200px"></v-img
      ></v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Welcome from "../components/Welcome.vue";

export default {
  components: { Welcome },
  data() {
    return {
      welcomeDialog: false,
    };
  },
};
</script>

<style></style>
